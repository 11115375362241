// // List all font properties as variables here.

// /* inria-sans-regular - latin */
// @font-face {
//   font-family: 'Inria Sans';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../fonts/inria-sans/inria-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
//   src: local('Inria'),
//     url('../../fonts/inria-sans/inria-sans-v14-latin-regular.eot?#iefix')
//       format('embedded-opentype'),
//     /* IE6-IE8 */
//       url('../../fonts/inria-sans/inria-sans-v14-latin-regular.woff2')
//       format('woff2'),
//     /* Super Modern Browsers */
//       url('../../fonts/inria-sans/inria-sans-v14-latin-regular.woff')
//       format('woff'),
//     /* Modern Browsers */
//       url('../../fonts/inria-sans/inria-sans-v14-latin-regular.ttf')
//       format('truetype'),
//     /* Safari, Android, iOS */
//       url('../../fonts/inria-sans/inria-sans-v14-latin-regular.svg#InriaSans')
//       format('svg'); /* Legacy iOS */
// }

// /* inter-regular - latin */
// @font-face {
//   font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../fonts/inter-sans/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
//   src: url('../../fonts/inter-sans/inter-v12-latin-regular.eot?#iefix')
//       format('embedded-opentype'),
//     /* IE6-IE8 */ url('../../fonts/inter-sans/inter-v12-latin-regular.woff2')
//       format('woff2'),
//     /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
//       url('../../fonts/inter-sans/inter-v12-latin-regular.woff') format('woff'),
//     /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
//       url('../../fonts/inter-sans/inter-v12-latin-regular.ttf')
//       format('truetype'),
//     /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
//       url('../../fonts/inter-sans/inter-v12-latin-regular.svg#Inter')
//       format('svg'); /* Legacy iOS */
// }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

// Font sizes
$font-xs: 0.5rem;
$font-s: 0.625rem;
$font-ms: 0.75rem;
$font-m: 0.875rem;
$font-l: 1rem;
$font-xl: 1.25rem;
$font-xxl: 1.5rem;

// Font weights
$bold: 700;
$medium: 500;
$medium-light: 400;

// Font family
$default-font: 'Inria', sans-serif;
$h2-digital-font: 'Inter', sans-serif;
$default-serif-font: 'Times New Roman', serif;
