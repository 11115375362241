.avatar-name-container {
  display: flex;

  .name {
    text-align: left;
    display: flex;
    padding-left: 15px;

    flex-direction: column;
    justify-content: center;
    font-size: 11px;
    text-transform: uppercase;

    b {
      font-weight: 700;
      display: inline;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}
