@use '../../../scss/abstracts/' as *;

.add-tags-dialog {
  .MuiPaper-root {
    max-width: 35rem;
    .MuiDialogContent-root {
      padding: 1rem;
    }
  }
  .tags-container-pop-up {
    max-height: 10rem;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
  }
}
