@use '../../scss/abstracts' as *;

.tag-search {
  margin: 1em 0;

  .search-field-with-two-inputs {
    @include align-center();
    border: 1px solid $blue-600;
    border-radius: 10px;

    &.red-border {
      border: 1px solid $warning-red;
    }

    .red-label {
      .MuiInputLabel-animated {
        color: $warning-red;
      }
    }

    .MuiInputLabel-animated {
      margin-left: 1rem;
    }

    .MuiInput-underline.MuiInputBase-root.MuiInput-root {
      margin-top: 0;

      &::before,
      &::after {
        border-bottom: none !important;
        transition: unset;
        animation: unset;
        transform: none;
      }

      .MuiInput-input {
        padding: 0.25rem 0.5rem;
      }
    }

    .MuiAutocomplete-clearIndicator {
      margin-right: 0.75em;
    }

    .error-container {
      position: relative;

      .error-icon {
        width: 1.8em;
        margin-right: 0.5em;
      }
    }

    .create-tag-button {
      width: 1.8em;
      margin-right: 0.5em;

      &.enabled {
        cursor: pointer;
      }
    }
  }

  .error-text {
    color: $warning-red;
    position: absolute;
    white-space: nowrap;
    margin-top: 0.2rem;
    margin-left: 1rem;
    font-size: $font-ms;
  }
}
