@use '/src/scss/abstracts/' as *;

.form-label-loc-list {
  width: 100%;
  height: 3.5rem;
  border: 0.05rem solid $gray-border !important;
  border-radius: 4px;

  .p-float-label {
    min-width: 33rem;
    max-height: 3rem;
    width: 100%;
    position: relative;

    .p-multiselect {
      display: flex;
      width: 100%;
      min-height: 3.5rem;
      border-color: $mui-input-border;

      &.p-disabled + label {
        opacity: 0.5;
      }
      .p-multiselect-label-container {
        max-height: 3rem;
        .selected-location-chip {
          background-color: $snc-blue-200;
          border-radius: 5px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 6.5em;
          max-width: 6.5em;
          padding: 0.25em;
          margin: 0.25em;
        }

        .p-multiselect-label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: $blue-600;
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-width: 2px;
        border-color: $blue-500;
      }
    }
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
      top: -0.3rem;
    }
    .p-multiselect-clear-icon {
      right: 2rem;
    }
    > label {
      transition-duration: 0.3s;
    }
  }
}
.assign-locations-multiselect-panel {
  z-index: 5000 !important;

  * {
    z-index: 5000 !important;
  }
  width: 35rem;

  .location-dropdown-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }

  .p-multiselect-header {
    background: $white;
    padding: 7px;
    .p-multiselect-select-all,
    .p-checkbox {
      display: none;
    }
    .p-multiselect-filter-container {
      height: 3rem;
      .p-inputtext {
        height: 100%;
        width: 95%;
        margin-right: 0.7rem;
        &:enabled:focus {
          box-shadow: none;
          border-width: 2px;
          border-color: $blue-500;
        }
      }
    }
    .p-multiselect-close {
      display: none;
    }
  }

  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      padding: 0;
      .p-multiselect-item {
        padding: 1rem;
        background-color: $white;
        &:focus {
          box-shadow: none;
        }
        &.p-highlight {
          background: $blue-200;
          color: $blue-600;
          &:not(.p-disabled):hover {
            background: $light-gray;
          }
        }
        > span {
          width: 100%;
          .list-item-style {
            display: flex;
            justify-content: space-between;
            font-size: $font-m;
            .list-item-name {
              width: 10rem;
              text-wrap: pretty;
            }
            &.disabled {
              opacity: 0.5;
            }
          }
        }
        .p-checkbox {
          display: none;
        }
      }
    }
  }
}
