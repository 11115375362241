@use '../../scss/abstracts/colors' as *;
@use '../../scss/abstracts/mixins' as *;

.powerbi-loading-component {
  position: absolute;
  top: 0;
  left: 0;
  @include align-center(space-around, center);
  height: 100%;
  width: 100%;
  background-color: $blue-100;
  z-index: 10;
  h3 {
    background-color: $blue-100;
    padding: 15px;
    border-radius: 4px;
  }
}
