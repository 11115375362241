.name-skeleton {
  position: relative;
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: 'Inria Sans', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(255, 255, 255);
  background-color: rgb(189, 189, 189) !important;
  animation: none !important;
}

.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.user-name-container {
  color: #234c8a;
  float: left;
  width: 43%;
  text-align: center;
  font-weight: 700;
  display: inline;
  font-size: 14px;
  text-transform: capitalize;
}

.profile-popover-container {
  border: 1px solid #5f9cd9;
  display: flex;
  flex-direction: column;
  width: 250px;
  align-items: center;
  text-align: center;
  box-shadow: none;

  .top-container {
    position: relative;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 50px;
    background: lightblue;
    color: darkblue;

    .company {
      font-weight: 700;
    }

    .edit {
      position: absolute;
      right: 15px;
      bottom: 0px;
    }
  }

  .avatar-upload {
    &:hover {
      cursor: pointer;
    }

    .avatar {
      width: 75px;
      height: 75px;
      margin-top: -42px;
      margin-bottom: 10px;
      background: #5f9cd9;
      border: 2px solid #5f9cd9;
    }
  }

  .name {
    font-weight: 500;
  }

  .mail {
    margin-top: 5px;
  }

  .actions {
    width: 100%;
    color: #5f9cd9;

    svg {
      color: #5f9cd9;
    }
  }

  hr {
    width: 100%;
    border-color: #5f9cd9;
  }
}

.avatar-name-container {
  display: flex;
}

.avatar {
  margin-top: -42px;
  margin-bottom: 10px;
  background: #5f9cd9;
  border: 2px solid #5f9cd9;
}
