// Accent colors for Cibuscell.
$blue-100: #f2f8fc;
$blue-150: #f3faff;
$blue-200: #c5e1f8;
$blue-250: #afd4f0;
$blue-300: #8cc2ea;
$blue-400: #5f9cd9;
$blue-500: #3d68aa;
$blue-515: #2081db;
$blue-550: #006eb4;
$blue-600: #234c8a;
$blue-700: #224c8a;

$purple: #2c2f6c;
$dark-purple: #004977;
$dark-green: #047654;
$blue-600-half-opaque: #234c8a77;
$blue-700-bit-opaque: #224c8a0d;

// Refer using easily readable names.
$light-blue: $blue-200;
$blue: $blue-400;
$dark-blue: $blue-600;

$blue-h2-dig: $blue-600;
$green-h2-dig: #00b48f;

$yellow: #ffc328;
$white: #ffffff;
$black: #000000;

$light-purple: #939fc7;
$light-beige: #ecfaffdb;
$text-light: #535353;
$dark-brown: #393838;
$team-blue: #5059c9;
$pipeline-blue: #0079bf;
$pipeline-yellow: #fbfc0b;
$pipeline-orange: #ffa500;
$gray-text: #7a7a7ae2;
$light-gray: #e2e2e2;
$dark-gray: #494949;
$darker-gray: #313131;
$grey-accent: #d2d2d2;
$light-grey: #f0f0f0;
$grey-accent: #bfbfbf;
$label-disabled-grey: #ebebeb;
$disabled-backgroung-grey: #f9f9f9;
$h2-dig-grey: #c1c1c1;
$gray-border: #c4c4c4;

$warning-red: #d32f2f;
$active-green: #30dd94;
$active-green-light: #d5f4e7;
$active-green-draft: #00a486;

$grey: #6a6a6a;
$dark-yellow: #db8a20;
$light-green: #009f7e;

$highlight-yellow: #ffedc8;

$draft-yellow-light: #ff8;

$inactive-red-light: #f77;

$h2-consume: #b3774b;
$h2-produce: #568949;
$h2-both: #b19635;
$phase-h2-consumer: #fffaf6;
$phase-h2-producer: #f8fff6;
$popup-producer: #8fc58138;
$popup-consumer: #f1b18345;
$popup-both: #f0d36b45;
$phase-ccus: #ececec;
$phase-other: #dcf0ff;

$h2-consume-lite: #f1b183;
$h2-produce-lite: #8fc581;
$h2-both-lite: #f0d36b;

//H2 Digital colors

$blue-cyan: #30ddc8;
$bright-blue: #3037dd;
$pastel-orange: #e27b6d;
$pastel-orange-light: #e27b6d80;
$pastel-purple: #3056dd80;
$pastel-cyan: #30d3dd80;

$link-active: $blue-cyan;

$hightlight: rgba(243, 239, 186, 0.71);

$sunflower-yellow: #f39c12;
$dodger-blue: #3498db;
$emerald-green: #2ecc71;
$mui-border-grey: #ced4da;
$mui-border-disabled-grey: #ced4daa6;
$mui-text-color-grey: #6c757d;
$mui-input-border: rgba(0, 0, 0, 0.23);
$mui-text-color-grey-2: rgba(0, 0, 0, 0.6);
$half-white: rgba(255, 255, 255, 0.5);
$dialogue-background-white: rgba(255, 255, 255, 0.75);
$light-cyan: #e1f6ff;
$button-shadow: #00000029;

// Sizing and costing colors

$snc-green: #08a576;
$snc-light-blue: #f8fbfe;
$snc-text-blue: #1997dd;
$snc-blue-200: #dcedfa;

$snc-bad: #e85050;
$snc-fine: #e49908;
$snc-good: #7aa220;

$snc-light-border: 1px solid rgba(35, 76, 138, 0.7);
