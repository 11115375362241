@use '../../../scss/abstracts/' as *;

.user-edit-form {
  .MuiPaper-elevation {
    width: 60rem;
  }
  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
  }
  .tags-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    .tags-title {
      @include align-center(flex-start);
    }
  }
  .form-label-loc-list {
    padding: 0;
    margin: 0 0 2rem 0;
  }
}
