.sidebar {
  padding-top: 15px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;

  .burger-icon {
    color: #5f9cd9;
  }

  .logo-wrapper {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-align: center;
    height: 135px;
  }

  .upgrade-button {
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px;
    padding-right: 19px;
  }

  .upgrade-text {
    font-family: Inria Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .close-button {
    width: 25px;
  }
}

.sidebar-open {
  .sidebar {
    width: 275px;
    min-width: 200px;
    overflow: hidden;

    .logo-wrapper {
      width: 100%;
      height: 75px;
    }

    .upgrade-text {
      font-size: 10px;
    }

    .upgrade-button {
      height: 50px;
    }
  }
}

.sidebar-closed-container {
  height: inherit !important;
}

.sidebar-close-menu-container {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding-top: 50px;
  padding-bottom: 25px;
}

.sidebar-close-menu-link {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 20px;
  text-decoration: none;
  color: #2e558f;
}

.sidebar-menu-icon-open {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-left: 2rem;
  color: #2e558f;
}

.sidebar-menu-icon-close {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  color: #2e558f;
}

.sidebar-menu {
  width: 250px;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
}

.sidebar-menu-items {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding-top: 50px;
  padding-bottom: 25px;
}

.sidebar-menu-items-links {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 20px;
  text-decoration: none;
  color: #2e558f;
}
