@use '../../scss/abstracts/colors' as *;
@use '../../scss/abstracts/mixins' as *;

.outer-page-container {
    height: 100%;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(128, 172, 237, 0.6) 100%
      );
    background-size: cover;
    background-position: center center;
  
    .report-outer-container {
      height: calc(100vh - 5px);
      width: 100%;
      float: left;
  
      .report {
        height: 100%;
        width: 100%;
  
        iframe {
          border: none;
        }
      }
    }
  }
