@use '../../scss/abstracts/mixins' as *;
.location-main-container {
  width: calc(100% - 12px);
  float: left;
  height: 91vh;
  padding: 10px;
  background-color: rgb(246 247 248);
}

.location-overview-row-container {
  display: flex;
  width: 100% !important;
  float: left;
  height: 100px !important;
  margin: 0 !important;
}

.location-details-title {
  font-size: 21pt;
  color: #383838;
  font-weight: bold;
  width: 70%;
}

.new-location-button-container {
  width: 10%;
}
.new-location-button-container-space {
  @include align-center();
  flex-direction: column;
}
.project-select-main-conatiner {
  border: 2px solid #e2e2e2;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  .project-select-location {
    @include align-center();
    flex-direction: column;
  }
  .projecttype-select-label {
    @include align-center();
    flex-direction: column;
    text-align: left;
    .clear-btn {
      margin-right: 1rem;
      font-size: 1rem;
      padding: 0.1rem;
    }
  }
}
