@use '../../scss/abstracts/' as *;

.tag {
  @include align-center();
  display: flex;
  max-width: 100%;
  width: fit-content;
  font-size: $font-m;
  background-color: $snc-blue-200;
  border: 1px solid $snc-blue-200;
  color: $blue-600;
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
  margin-top: 1em;
  margin-right: 1em;

  .key,
  .value {
    display: inline-block;
  }
  .value {
    font-weight: $bold;
    margin-left: 0.25rem;
    @include text-ellipsis();
    &.overflow {
      cursor: pointer;
    }
  }

  .delete-button {
    margin-left: 0.6em;
    width: 0.6em;
    height: 0.6em;
    cursor: pointer;
  }

  &.delete-enabled:hover {
    border: 1px solid $blue-600;
  }
  &.public {
    border: 1px solid $active-green;
  }
}
