.page-container {
  display: flex;
  height: 100%;

  .right-column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-container {
    width: 100%;
  }

  .header-component {
    width: calc(100% - 150px);
    float: left;
  }

  .profile-component {
    width: 150px;
    float: left;
    text-align: right;
  }

  .sidebar-open-right-column {
    width: calc(100% - 275px) !important;
  }
}

.wrapper-container {
  height: 100%;
}
