@use '../../scss/abstracts/' as *;

.tag-filter-root {
  padding: 0.5rem 0;

  .selected-tags-header {
    color: $blue-600;
    font-weight: $bold;
    margin: 0.5rem;
  }

  .selected-tags-list {
    margin: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    max-height: 30vh;
    overflow: auto;
  }

  .tag-search-in-filters {
    .MuiInputLabel-animated {
      top: -0.6rem;
      color: $mui-text-color-grey;

      &.MuiInputLabel-shrink {
        top: -1rem;
        color: $mui-text-color-grey;
      }
    }

    .tag-search-field {
      padding: 0.4rem;
      color: $h2-dig-grey;
    }
    .search-field-with-two-inputs {
      margin-top: 1.7rem;
      border: $h2-dig-grey 1px solid;
      border-radius: 4px;
    }
  }
}
