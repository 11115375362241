.new-driverfunction-button {
  display: flex;
  float: right;
  bottom: 1rem;
}
.driver-functions {
  display: flex;
  flex-direction: column;
}
.edit-buttons {
  color: #234c8a !important;
}
