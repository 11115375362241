@use '../../scss/abstracts/mixins' as *;

.project-main-container {
  width: calc(100% - 12px);
  float: left;
  padding: 10px;
  background-color: rgb(246 247 248);
}

.project-overview-row-container {
  display: flex;
  width: 100% !important;
  float: left;
  height: 150px !important;
  margin: 0 !important;
}

.project-overview-header-container {
  height: 50px;
  float: left;
  padding: 10px;
  width: calc(100% - 22px);
  padding-left: 22px;
}

.project-details-title {
  font-size: 21pt;
  color: #383838;
  font-weight: bold;
  width: 70%;
  float: left;
}

.new-project-button-container {
  width: 28%;
  float: left;
  text-align: right;
  padding-right: 2%;
}
.new-project-button {
  background-color: #234c8a !important;
  color: #ffff !important;
  &:hover {
    background-color: #ffff !important;
    color: #234c8a !important;
  }
}
.advanced-button:hover {
  color: #3d68aa;
}

.project-overview-cell-container {
  background-color: white;
  padding: 0px !important;
  height: 100% !important;
  border: 1px solid rgb(232 234 238);
  border-radius: 6px;
  margin-left: 2% !important;
}

.project-overview-cell-column1 {
  padding: 10px;
  padding-top: 30px;
  width: 65%;
  float: left;
}
.project-overview-cell-column2 {
  padding: 10px;
  width: 32%;
  float: left;
  font-size: x-large;
}
.project-overview-row-basic {
  align-items: center;
  float: left;
  font-family: 'Inria Sans';
  font-weight: bold;
  vertical-align: middle;
  width: 100%;
}
.project-overview-total-count-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.project-overview-cell-row1 {
  display: flex;
  height: 45px;
  font-size: 11pt;
}

.project-overview-cell-row2 {
  display: flex;
  height: 100px;
  text-align: center;
  font-size: 10pt;
}

.project-overview-cell-full-row1 {
  height: 50px;
  font-size: 11pt;
  text-align: center;
  padding-top: 20px;
}

.project-overview-cell-full-row2 {
  height: 75px;
  text-align: center;
  font-size: 16pt;
  align-items: center;
}

.project-overview-graph-icon {
  width: 100%;
}

.new-project-image-container {
  width: calc(30% - 40px);
  float: left;
  padding: 20px 20px 0px 0px;
}

.new-project-image-title {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 9pt;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  text-align: center;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.new-project-form-container {
  width: 100%;
  float: left;
}

.new-project-address-container {
  width: 13rem;
  float: left;
  padding-right: 20px;
}

.new-project-name-container {
  width: 13rem;
  float: left;
  padding-right: 20px;
}

.new-project-description-container {
  width: 26rem;
  float: left;
  padding-right: 20px;
}

.new-project-description-field {
  width: calc(100% - 24px);
}

.new-project-image-icon {
  left: 0px;
  top: 0px;
  width: 100% !important;
  height: 100% !important;
  color: gray;
}

.new-project-image {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.error-class {
  color: #d32f2f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
}

.new-project-image-wrap {
  float: left;
  width: 100%;
  height: 93px;
}

.delete-project-dialog-container {
  width: 700px;
}

.delete-project-fragment1-container {
  padding: 20px 10px 10px 10px;
  font-size: 12pt;
}
.delete-project-fragment1-text {
  line-height: 1.5;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.01071em;
  display: block;
  color: #0d3f70;
}
.delete-project-fragment1-confirmation {
  color: #eb2020;
  font-size: 13pt;
  padding-top: 10px;
  font-weight: 600;
}
.delete-project-fragment1-users {
  color: #eb2020;
}
.delete-project-username-container {
  height: 130px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.data-load-container {
  @include align-center();
}

.edit-project-logo-wrap {
  width: 360px;
  height: 180px;
  margin-bottom: 7px;
}
