@use '../../scss/abstracts/' as *;

.table-wrapper {
  @include align-center();
  flex-direction: column;
  .tags-container {
    max-height: 15rem;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
  }
}
