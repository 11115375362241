@use '../../../scss/abstracts/' as *;

.new-user-dialog-team {
  .team-field {
    width: 17rem;
    padding-right: 2rem;
  }

  .no-team-msg {
    color: red;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding-bottom: 0.7rem;
  }

  .inputs-container {
    display: flex;
    gap: 10px;
    .image-container {
      flex-basis: 30%;
      margin: 1rem 0;
      .error-class {
        text-align: center;
      }
    }
    .textfield-container {
      padding-right: 2rem;
      display: flex;
      flex-wrap: wrap; /* Allow items to wrap onto new rows */
      justify-content: space-between;
      .MuiTextField-root {
        padding-right: 2rem;
      }
    }
  }

  .action-btns {
    width: 9rem;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    float: right;
  }
  .checkbox-container {
    padding: 2rem;
    &.MuiFormGroup-root {
      flex-direction: row;
    }
  }

  .text-input {
    width: calc(50% - 11px);
    float: left;
  }
  .secondary-email-input-in-invitation-mode {
    width: 20rem !important;
    margin-bottom: 1rem;
  }
  .field-select {
    width: calc(50% - 11px);
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .provider-location-select {
    .MuiFormControl-root {
      width: 60%;
    }
  }

  .tags-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    .tags-title {
      @include align-center(flex-start);
    }
  }
}
