.report-container {
  height: 100vh;
  width: 100%;
  position: relative;
  .report {
    height: 100%;
    width: 100%;
    iframe {
      border: none;
    }
  }
}
